import React, { Fragment, Component } from "react";
import Header from "../components/Header";
import { Element, Events, scrollSpy } from "react-scroll";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
// import GetDocsRight from "../components/Doctors/departments/section-left";
import GetDocsLeft from "../components/Doctors/departments/section-right";
import SEO from "../components/SEO";
import DeptList from "../components/Doctors/departments/deptListKids";
class PageAbout extends Component {
  componentDidMount() {
    Events.scrollEvent.register("begin", function (to, element) {
      console.log("begin", arguments);
    });

    Events.scrollEvent.register("end", function (to, element) {
      console.log("end", arguments);
    });

    scrollSpy.update();
  }

  componentWillUnmount() {
    Events.scrollEvent.remove("begin");
    Events.scrollEvent.remove("end");
  }

  handleSetActive(to) {
    console.log(to);
  }

  render() {
    return (
      <Fragment>
        <Header />
        <PageHeader
          bgImg={require("../assets/img/doctors-bg.jpg")}
          title="Doctors in our Kangaroocare Kids"
          content=""
        />
        <DeptList onSetActiveFunc={this.handleSetActive} />
        <SEO />

        <Element name="paediatrics_and_subspeciatilies" className="element">
          <GetDocsLeft
            hospitals="kckidsclinic"
            deptTitle="Paediatrics and SubSpecialities"
            dept="paediatrics_and_subspeciatilies"
            image="separator9.svg"
          />
        </Element>

        <Element name="developmental_pediatrics" className="element">
          <GetDocsLeft
            hospitals="kckidsclinic"
            deptTitle="Developmental Pediatrics"
            dept="developmental_pediatrics"
            image="separator10.svg"
          />
        </Element>
        <Element name="anaesthesia_and_critical_care" className="element">
          <GetDocsLeft
            hospitals="kckidsclinic"
            deptTitle="Anaesthesia and Critical Care"
            dept="anaesthesia_and_critical_care"
            image="separator12.svg"
          />
        </Element>

        <Element name="cosmetologists_and_plastic_surgeons" className="element">
          <GetDocsLeft
            hospitals="kckidsclinic"
            deptTitle="Cosmetologist and Plastic Surgery"
            dept="cosmetologists_and_plastic_surgeons"
            image="separator19.svg"
          />
        </Element>

        <Element name="other_specialities" className="element">
          <GetDocsLeft
            hospitals="kckidsclinic"
            deptTitle="Other Specialities"
            dept="other_specialities"
            image="separator12.svg"
          />
        </Element>
        <Footer />
        <MobileMenu />
      </Fragment>
    );
  }
}

export default PageAbout;
