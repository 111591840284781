import React, { Fragment } from "react";
import Header from "../components/Header";
import PageHeader from "../components/PageHeader";
import Footer from "../components/Footer";
import MobileMenu from "../components/MobileMenu";
// import GetDocsRight from "../components/Doctors/departments/section-left";
import GetDocsLeft from "../components/Doctors/departments/section-right";
import SEO from "../components/SEO";

const PageAbout = (props) => {
  return (
    <Fragment>
      <Header />
      <PageHeader
        bgImg={require("../assets/img/doctors-bg.jpg")}
        title="Doctors in our Clinics"
        content=""
      />
      <SEO />
      <GetDocsLeft
        hospitals="kcclinicsnagarabhavi"
        deptTitle="Paediatrics and SubSpecialities"
        dept="paediatrics_and_subspeciatilies"
        image="separator9.svg"
      />
      <GetDocsLeft
        hospitals="kcclinicsnagarabhavi"
        deptTitle="Obstetrics and Gynecology"
        dept="obstetrics_and_gynecology"
        image="separator9.svg"
      />
      <GetDocsLeft
        hospitals="kcclinicsnagarabhavi"
        deptTitle="Fertility & IVF"
        dept="infertility_and_ivf"
        image="separator9.svg"
      />
      <GetDocsLeft
        hospitals="kcclinicsnagarabhavi"
        deptTitle="Ultrasound and Radiology"
        dept="ultrasound_and_radiology"
        image="separator9.svg"
      />
      <GetDocsLeft
        hospitals="kcclinicsnagarabhavi"
        deptTitle="other specialities"
        dept="other_specialities"
        image="separator9.svg"
      />
      <GetDocsLeft
        hospitals="kcclinicsnagarabhavi"
        deptTitle="physicians"
        dept="physicians"
        image="separator9.svg"
      />

      {/* <GetDocsLeft
        hospitals="kcclinicsnagarabhavi"
        deptTitle="Developmental Pediatrics"
        dept="developmental_pediatrics"
        image="separator9.svg"
      /> */}

      {/* <GetDocsLeft
        hospitals="kcclinicsnagarabhavi"
        deptTitle="Other Specialities"
        dept="other_specialities"
        image="separator9.svg"
      /> */}

      <Footer />
      <MobileMenu />
    </Fragment>
  );
};

export default PageAbout;
