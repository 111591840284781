import React from "react";

function ServiceItem(props) {
  return (
    <div className="col-sm-6 col-lg-4">
      <div className="service-item">
        <figure className="service-thumb">
          <a
            href={"/files/" + props.file}
            target="_blank"
            rel="noopener noreferrer"
            className="stretched-link"
          >
            <img
              src={require("../../assets/img/media/downloads/" + props.image)}
              alt={props.title}
            />
          </a>

          <figcaption className="service-txt">
            <h5>{props.title}</h5>
          </figcaption>
        </figure>
        <div className="service-content">
          <div className="service-content-inner">
            <img
              src={require("../../assets/img/separator20.svg")}
              alt=""
              className="dlIcon"
            />
            <h5>
              <a
                href={"/files/" + props.file}
                target="_blank"
                rel="noopener noreferrer"
                className="stretched-link"
              >
                {props.title}
              </a>
            </h5>
          </div>
        </div>
      </div>
    </div>
  );
}

export default ServiceItem;
