import React from "react";
import PressRelease from "./Pr";
import prData from "../../data/PressRelease/index.json";

const PRPage = () => {
  return (
    <div className="press-release-wrapper sm-top sm-bottom">
      <div className="container">
        <div className="row">
          {prData.map((prd) => (
            <PressRelease
              key={prd.id}
              id={prd.id}
              title={prd.title}
              date={prd.date}
              image={prd.image}
            />
          ))}
        </div>
      </div>
    </div>
  );
};

export default PRPage;
