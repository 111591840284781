import React, { Fragment } from "react";
import PageWrapper from "../../components/PageWrapper";
import PageHeader from "../../components/PageHeader";
import TrainingContent from "./Trainingcontent";

const BlogPage = ({ sidebar_position, blog_type, sidebar }) => {
  return (
    <Fragment>
      <PageHeader
        bgImg={require("../../assets/img/gallery-bg.jpg")}
        title={"Training"}
        content=""
      />
      
      <PageWrapper classes={"blog-page-content-area"}>
        <TrainingContent/>
      </PageWrapper>
    </Fragment>
  );
};

export default BlogPage;
