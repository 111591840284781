import React from "react";

const PressRelease = (props) => {
  return (
    <div className="col-md-6">
      <div className="icon-box-item">
        <div className="icon-box__icon">
          <img
            src={require("../../assets/img/media/press/" + props.image)}
            alt={props.title}
            className="img-responsive pressPic"
          />
        </div>
        <div className="icon-box__info">
          <div className="row">
            <h5>{props.title}</h5>
          </div>
          <div className="row">
            <h6>{props.date}</h6>
          </div>
        </div>
      </div>
    </div>
  );
};

export default PressRelease;
